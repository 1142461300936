import React, { useState, useEffect, act } from 'react';
import { useKeyPress, fetchProductData } from "../utils";
import { useScreen } from '../context';

import PrimaryTable from './widgets/primaryTable';
import Pagination from './pagination';
import SearchBar from './searchBar';
import SalesOrderConfirmationScreen from './salesOrder';

import { eqsiData } from '../dummyData';

import { Product } from '../types';

import { handleGPchange, handleGPupdate, calculateSalesOrderBasketTotals, handleProductSelect, removeItemFromBasket} from './basketUtils';

type NewEQSIprops = {
  customerId?: string;
  closeCard: () => void;
}

const NewEQSI = ({ customerId, closeCard }: NewEQSIprops) => {
  const tabOptions = ["All", "Quotes", "Sales Orders", "Invoiced", "Completed"];
  const [eqsiCustomerId, setEqsiCustomerId] = useState(customerId);
  const [customerData, setCustomerData] = useState(null as null | any);
  const [productData, setProductData] = useState([] as any[]);
  const [previewProductId, setPreviewProductId] = useState(null as null | string);
  const [basket, setBasket] = useState([] as {product: Product, quantity: number, basketPrice: number, gp: number}[]);
  const [basketStats, setBasketStats] = useState({ subtotal: 0, profit: 0, grossProfit: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25);
  const [filteredAndSearchedProducts, setFilteredAndSearchedProducts] = useState([] as any[]);
  const [activeBasketItem, setActiveBasketItem] = useState(null as null | string);
  const [confirmationScreen, setConfirmationScreen] = useState(false);

  const { setScreenId, navCollapsed, toggleNavCollapsed } = useScreen();
  setScreenId("EQSI-SO")
  
  const clearEQSI = () => {
    setEqsiCustomerId(undefined);
    setCustomerData(null);
    setProductData([]);
    setPreviewProductId(null);
    setBasket([]);
    setBasketStats({ subtotal: 0, profit: 0, grossProfit: 0 });
    setActiveBasketItem(null);
  }

  const backAction = () => {
    if (!eqsiCustomerId) {
      clearEQSI();
    }

    setEqsiCustomerId(undefined);
  }
  useKeyPress('F1', backAction);

  const upAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item) => item.product["Product code"] === activeBasketItem);
      if (indexOfItem !== 0) setActiveBasketItem(basket[indexOfItem - 1].product["Product code"]);
    }
  }
  useKeyPress('ArrowUp', upAction);

  const downAction = () => {
    if (activeBasketItem) {
      const indexOfItem = basket.findIndex((item) => item.product["Product code"] === activeBasketItem);
      if (indexOfItem !== basket.length - 1) setActiveBasketItem(basket[indexOfItem + 1].product["Product code"]);
    }
  }
  useKeyPress('ArrowDown', downAction);
  

  useEffect(() => {
    if (!eqsiCustomerId) return;
    if (!navCollapsed) toggleNavCollapsed();
    
    const fetchData = async () => {
      try {
        let customerData = eqsiData.find((customerObject: any) => customerObject.customer.name === eqsiCustomerId);
        const { products, productCategories } = await fetchProductData();

        if (!customerData) return;

        products.forEach((product, index) => {
          Math.random() > 0.6
            ? product["Terms Discount"] = (Math.random() * 0.35 + 0.05)
            : product["Terms Discount"] = 0

          product["postTermsPrice"] = product["Terms Discount"] 
            ? parseFloat((product["Trade Price"] - (product["Trade Price"] * product["Terms Discount"])).toFixed(2))
            : product["Trade Price"];
        })

        // Sort products by stock, with 0 stock at the end
        products.sort((a, b) => {
          if (a["Total Stock"] === 0) return 1;
          if (b["Total Stock"] === 0) return -1;
          return (b["Total Stock"] as number) - (a["Total Stock"] as number);
        });

        setProductData(products);
        setFilteredAndSearchedProducts(products);
        setCustomerData(customerData);
        // Fetch customer data
      } catch (error) {
        console.error('There was a problem fetching customer data:', error);
      }
    };


    fetchData();
  }, [eqsiCustomerId]);

  useEffect(() => {
    console.log(filteredAndSearchedProducts);
  }, [filteredAndSearchedProducts]);

  useEffect(() => {
    setBasketStats(calculateSalesOrderBasketTotals(basket));
  }, [basket]);
  
  const displayPreviewProduct = () => {
    if (previewProductId) {
      console.log(previewProductId, productData);
      let product = productData.find((product) => product["Product code"] === previewProductId);
      console.log(product);
      let relatedProducts = productData.filter((otherProducts) => otherProducts["Prod Group"] === product["Prod Group"] && otherProducts["Product code"] !== product["Product code"]).slice(0, 12);
      let productAlreadyInBasket = basket.find((item) => item.product["Product code"] === previewProductId);
      let canAffordProduct = customerData.credit - basketStats.subtotal - product["Trade Price"] >= 0;
      if (product) {
        return <>
        <div className="dashboard-card flex-grow p-4 flex flex-col">
          <div className="flex items-end gap-4">
            <img src={product.Pict} alt={product.Description} className="w-14 h-14 rounded-md" />
            <div>
              <p className="uppercase text-xs">Product</p>
              <h3 className="font-semibold text-xl">{product.Description}</h3>
            </div>
            <button className="ml-auto" onClick={() => setPreviewProductId(null)}>
              <i className="fa-solid fa-times"></i>
            </button>
          </div>

          <div className="flex gap-4 mt-4">
            <div className="flex-grow">
              <p className="text-sm">Product Code: {product["Product code"]}</p>
              <p className="text-sm">Trade Price: £{product["Trade Price"]}</p>
              <p className="text-sm">Cost Price: £{product["Cost Price"]}</p>
              <p className="text-sm">Product Group: {product["Prod Group"]}</p>
            </div>
          </div>

          <div className="flex justify-end gap-4">
            {productAlreadyInBasket
              ? <button
                    onClick={() => {
                      const updatedBasket = [...basket];
                      updatedBasket.splice(basket.findIndex((item) => item.product["Product code"] === previewProductId), 1);
                      setBasket(updatedBasket);
                    }}
                    className="btn btn-secondary"
                  >
                    Remove from Basket
                  </button>

                : canAffordProduct
                    ? <button className="btn btn-primary" onClick={() => handleProductSelect(product["Product code"], productData, basket, setBasket, setActiveBasketItem)}>
                        Add to Basket
                      </button>
                    : <button
                        onClick={() => removeItemFromBasket(product["Product code"], basket, setBasket, setActiveBasketItem, activeBasketItem)}
                        className="btn bg-red-500 text-white"
                        disabled={true}
                      >
                        <i className="fa-solid fa-circle-exclamation"></i>&nbsp;&nbsp;Insufficient Credit Remaining
                      </button>
              }
          </div>
        </div>

          <div className="dashboard-card flex-grow p-4">
            <h3 className="font-semibold text-lg mb-2">Related Products</h3>
            <div className="flex overflow-x-scroll gap-4 pb-2">
              {relatedProducts.map((product, index) => (
                <div 
                  key={index} 
                  className="max-w-32 cursor-pointer hover:underline shrink-0"
                  onClick={() => setPreviewProductId(product["Product code"])}
                >
                  <img src={product.Pict} alt={product.Description} className="w-24 h-24 rounded-md" />
                  <p className="text-sm">{product.Description.length > 30 ? product.Description.slice(0, 30) + "..." : product.Description}</p>
                  <p className="font-semibold">£{product["Trade Price"].toLocaleString()}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      }
    }
  }

  return (
    <div className="flex flex-col gap-6 md:flex-row items-start w-full">
      {confirmationScreen 
        ? <SalesOrderConfirmationScreen
            customerData={customerData}
            preConfirmationBasket={basket}
            closeCard={() => setConfirmationScreen(false)}
          />
        : <>
            {!eqsiCustomerId && (
              <div className="flex-grow flex flex-col gap-4">
              <PrimaryTable
                source="EQSI"
                itemData={eqsiData}
                tabOptions={tabOptions}
                onClickEvent={(id: string) => setEqsiCustomerId(id)}
              />
              </div>
            )}

            {customerData && (
              <div className="flex flex-col gap-4 flex-grow">
                <div className="dashboard-card flex-grow p-4 flex flex-col">
                  <div className="flex items-end gap-4">
                    <img 
                      src={`/customerImages/${customerData.customer.image}.png`}
                      alt={customerData.customer.name} 
                      className="w-14 h-14 rounded-md" 
                    />

                    <div>
                      <p className="uppercase text-xs">Customer</p>
                      <h3 className="font-semibold text-xl">{customerData.customer.name}</h3>
                    </div>

                    
                    <button className="ml-auto" onClick={() => clearEQSI()}>
                      <i className="fa-solid fa-times"></i>
                    </button>
                  </div>
                </div>

                {previewProductId
                  ? displayPreviewProduct()
                  : <>
                      <SearchBar initialData={productData} setResults={setFilteredAndSearchedProducts} />

                      <div className="flex flex-col gap-4 dashboard-card p-2 md:p-4 rounded-lg">
                        <table className="table-auto w-full">
                          <thead className="sticky top-0 bg-white">
                            <tr>
                              <th className="text-left px-1 text-sm">Product</th>
                              <th className="text-left px-1 text-sm">SKU</th>
                              <th className="text-left px-1 text-sm">Category</th>
                              <th className="text-left px-1 text-sm">Cost Price</th>
                              <th className="text-left px-1 text-sm">Trade Price</th>
                              <th className="text-left px-1 text-sm">Terms</th>
                              <th className="text-left px-1 text-sm">Customer Price</th>
                              <th className="text-left px-1 text-sm">Stock</th>
                              <th className="text-left pl-4 text-sm ">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredAndSearchedProducts.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((item: Product, rowIndex: number) => (
                              <tr key={rowIndex}>
                                <td>
                                  <div className="flex gap-4 items-center pr-2">
                                    <img src={item.Pict} alt={item.Description} className="w-14 h-14 rounded-md" />
                                    <div>
                                      <p className="font-semibold text-sm">{item.Description.length > 45 ? item.Description.slice(0, 45) + "..." : item.Description}</p>
                                      <p className="text-xs">{item["Supp"]}</p>
                                    </div>
                                  </div>
                                </td>
                                <td><p className="text-sm text-[#3A5BFF]  ">{item["Product code"]}</p></td>
                                <td><p className="text-sm px-1">{item["Prod Group"]}</p></td>
                                <td><p className="text-sm px-1">£{item["Cost Price"]}</p></td>
                                <td><p className="text-sm px-1">£{item["Trade Price"]}</p></td>
                                <td><p className="text-sm px-1">{item["Terms Discount"] ? (item["Terms Discount"] * 100).toFixed(2) + "%" : "None"}</p></td>
                                <td><p className="text-sm px-1">£{item["postTermsPrice"]}</p></td>
                                <td><p className="text-sm px-1">{item["Total Stock"]?.toLocaleString()}</p></td>
                                <td>
                                  {basket.find((basketItem) => basketItem.product["Product code"] === item["Product code"])
                                    ? <button
                                        onClick={() => removeItemFromBasket(item["Product code"], basket, setBasket, setActiveBasketItem, activeBasketItem)}
                                        className="btn bg-red-400 text-white w-14 flex justify-center ml-4"
                                      >
                                        <i className="fa-solid fa-trash-xmark"></i>
                                      </button>
                                    : <button 
                                        onClick={() => handleProductSelect(item["Product code"], productData, basket, setBasket, setActiveBasketItem)}
                                        className="btn btn-primary w-14 flex justify-center ml-4"
                                      >
                                        <i className="fa-solid fa-basket-shopping-plus"></i>
                                      </button>
                                  }
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>


                        <Pagination 
                          numberOfEntries={productData.length} 
                          currentPage={currentPage} 
                          setCurrentPage={setCurrentPage}
                        />
                      </div> 
                    </>
                }
              </div>
            )}

            {basket.length > 0 && (
              <div className="rounded-l-xl overflow-y-scroll w-[450px] bg-white dashboard-card sticky top-10">
                <div className="border-b border-gray-300 px-4 pt-4 pb-2">
                  <h3 className="font-semibold text-xl text-center">Building Sales Order</h3>
                </div>
                
                {basket.map((item, index) => (
                  <div 
                    key={index} 
                    className={`cursor-pointer border-b border-gray-200 p-2 ${activeBasketItem === item.product["Product code"] ? "bg-[#F2F4f7]" : ""}`}
                  >
                    <div className="flex justify-between items-start">
                      <div className="flex flex-col gap-2" onClick={() => setActiveBasketItem(item.product["Product code"])}>
                        <p className="text-sm">{item.product.Description}</p>
                        <div className="flex gap-2 items-center justify-start">
                          <div className="flex rounded border border-gray-300 bg-white items-center justify-start">
                            <div className="border-gray-300 border-r px-1">
                              <p className="text-sm">£</p>
                            </div>
                            <div className="border-gray-300 border-r px-1">
                              <input 
                                type="number" 
                                value={item.basketPrice} 
                                style={{ width: `${item.basketPrice.toString().length * 9 + 25}px` }}
                                onChange={(e) => {
                                  const updatedBasket = [...basket];
                                  updatedBasket[index].basketPrice = parseFloat(e.target.value);
                                  setBasket(updatedBasket);
                                }}
                                className="bg-transparent text-sm"
                              />
                            </div>
                            <div className="border-gray-300 border-r px-1 shrink-0">
                              <p className="text-sm shrink-0 no-wrap">GP %</p>
                            </div>
                            <div className="border-gray-300 px-1">
                              <input 
                                type="number"
                                id={`${item.product["Product code"]}-GP`}
                                value={parseFloat(item.gp.toFixed(2))}
                                onChange={(e) => handleGPupdate(e, index, basket, setBasket)}
                                onKeyDown={(e) => handleGPchange(e, index, basket, setActiveBasketItem)}
                                className="bg-transparent text-sm w-12"
                              />
                            </div>
                          </div>

                          <div className="flex rounded-2xl px-1 border border-gray-300 bg-white items-center justify-start">
                            <button
                              onClick={() => {
                                const updatedBasket = [...basket];
                                updatedBasket[index].quantity -= 1;
                                setBasket(updatedBasket);
                              }}
                              disabled={item.quantity === 1}
                              className="border-gray-300 border-r px-1"
                            >
                              -
                            </button>
                            <input
                              type="number"
                              value={item.quantity}
                              className="px-1 bg-transparent text-center"
                              style={{ width: `${item.quantity.toString().length * 9 + 25}px` }}
                              onChange={(e) => {
                                const updatedBasket = [...basket];
                                updatedBasket[index].quantity = parseInt(e.target.value);
                                setBasket(updatedBasket);
                              }}
                            />
                            <button
                              onClick={() => {
                                const updatedBasket = [...basket];
                                updatedBasket[index].quantity += 1;
                                setBasket(updatedBasket);
                              }}
                              className="border-gray-300 border-l px-1"
                            >
                              +
                            </button>
                          </div>
                          
                        </div>
                      </div>

                      <button 
                        className=""
                        onClick={() => {removeItemFromBasket(item.product["Product code"], basket, setBasket, setActiveBasketItem, activeBasketItem)}}
                      >
                        <i className="fa-solid fa-trash"></i>
                      </button>
                    </div>

                    {activeBasketItem === item.product["Product code"] && ( 
                      <div className="table w-full text-left mt-4">
                        <thead>
                          <tr>
                            <th className="border-b border-gray-400 text-sm">Date</th>
                            <th className="border-b border-gray-400 text-sm">Action</th>
                            <th className="border-b border-gray-400 text-sm">Price</th>
                            <th className="border-b border-gray-400 text-sm">Quantity</th>
                            <th className="border-b border-gray-400 text-sm">Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td><p className="text-sm px-1">05/09/24</p></td>
                            <td><p className="text-sm px-1">Quote</p></td>
                            <td><p className="text-sm px-1">£13.43</p></td>
                            <td><p className="text-sm px-1">12</p></td>
                            <td><p className="text-sm px-1">£161</p></td>
                          </tr>
                          <tr>
                            <td><p className="text-sm px-1">01/09/24</p></td>
                            <td><p className="text-sm px-1">Sale</p></td>
                            <td><p className="text-sm px-1">£13.35</p></td>
                            <td><p className="text-sm px-1">100</p></td>
                            <td><p className="text-sm px-1">£1335</p></td>
                          </tr>
                          <tr>
                            <td><p className="text-sm px-1">28/08/24</p></td>
                            <td><p className="text-sm px-1">Quote</p></td>
                            <td><p className="text-sm px-1">£14</p></td>
                            <td><p className="text-sm px-1">1</p></td>
                            <td><p className="text-sm px-1">£14</p></td>
                          </tr>
                        </tbody>
                      </div> 
                    )}
                  </div>
                ))}

                <div className="flex justify-between mt-auto border-b p-4">
                  <h3 className="font-semibold">Subtotal:</h3>
                  <h3 className="font-semibold">£{basketStats.subtotal.toFixed(2)}</h3>
                </div>

                <div className="flex justify-between px-4 pt-4 pb-2">
                  <h3 className="font-semibold">Profit:</h3>
                  <h3 className="font-semibold">£{basketStats.profit.toFixed(2)}</h3>
                </div>
              
                <div className="flex justify-between px-4">
                  <h3 className="font-semibold">Gross Profit:</h3>
                  <h3 className="font-semibold">{basketStats.grossProfit.toFixed(2)}%</h3>
                </div>

                <div className="px-4 pb-4">
                  <button 
                    className="btn btn-primary w-full mt-4 flex items-center justify-center uppercase font-extrabold hover:bg-black focus:bg-black"
                    onClick={() => setConfirmationScreen(true)}
                    id="continueButton"
                  >Continue</button>
                </div>
              </div>
            )}
          </>
      }
    </div>
  );
}

export default NewEQSI;