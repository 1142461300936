import { Product } from '../types';

export const handleGPupdate = (
  e: React.ChangeEvent<HTMLInputElement>,
  index: number,
  basket: {product: Product, quantity: number, basketPrice: number, gp: number}[],
  setBasket: React.Dispatch<React.SetStateAction<{product: Product, quantity: number, basketPrice: number, gp: number}[]>>
) => {
  const updatedBasket = [...basket];
  let userEnteredGp = parseFloat(e.currentTarget.value);
  let item = updatedBasket[index];
  
  let resultingBasketPrice = (userEnteredGp && userEnteredGp > 0)
    ? item.product["Cost Price"] + (item.product["Cost Price"] * (parseFloat(e.currentTarget.value) / 100))
    : item.product["postTermsPrice"] || item.product["Trade Price"];

  updatedBasket[index].basketPrice = parseFloat(resultingBasketPrice.toFixed(2));
  updatedBasket[index].gp = userEnteredGp;

  setBasket(updatedBasket);
};

export const handleGPchange = (
  e: React.KeyboardEvent<HTMLInputElement>, 
  index: number,
  basket: {product: Product, quantity: number, basketPrice: number, gp: number}[],
  setActiveBasketItem: (productCode: string) => void
) => {
  if (e.key === "Enter") {
    e.preventDefault();
    if (index !== basket.length - 1) {
      setActiveBasketItem(basket[index + 1].product["Product code"]);
      let nextItemGPinput = document.getElementById(`${basket[index + 1].product["Product code"]}-GP`);
      if (nextItemGPinput) nextItemGPinput.focus();
    }
    else {
      let continueButton = document.getElementById("continueButton");
      if (continueButton) continueButton.focus();
    }
  }
};

export const calculateSalesOrderBasketTotals = (
  basket: {product: Product, quantity: number, basketPrice: number, gp: number}[]
) => {
  let subtotal = 0;
  let cost = 0
  basket.forEach((item) => {
    subtotal += item.basketPrice * item.quantity;
    cost += item.product["Cost Price"] * item.quantity;
  });

  let profit = subtotal - cost;
  let grossProfit = (profit / cost) * 100;
  return { subtotal, profit, grossProfit };
}

export const handleProductSelect = (
  id: string,
  productData: Product[],
  basket: {product: Product, quantity: number, basketPrice: number, gp: number}[],
  setBasket: React.Dispatch<React.SetStateAction<{product: Product, quantity: number, basketPrice: number, gp: number}[]>>,
  setActiveBasketItem: (productCode: string) => void
) => {
  const latestProductData = [...productData];

  console.log("Handle Product Select: ", id, latestProductData);
  const product = latestProductData.find((product) => product["Product code"] === id);
  if (!product) return;

  const itemIndex = basket.findIndex((item) => item.product["Product code"] === id);
  if (itemIndex > -1) {
    const updatedBasket = [...basket];
    updatedBasket[itemIndex].quantity += 1;
    setBasket(updatedBasket);
  } else {
    let basketPrice = product["Terms Discount"] ? parseFloat((product["Trade Price"] - (product["Trade Price"] * product["Terms Discount"])).toFixed(2)) : product["Trade Price"];
    let gp = ((basketPrice - product["Cost Price"]) / product["Cost Price"]) * 100;
    setBasket([...basket, { product, quantity: 1, basketPrice, gp }]);
    setActiveBasketItem(id);
  }
};

export const removeItemFromBasket = (
  id: string,
  basket: {product: Product, quantity: number, basketPrice: number, gp: number}[],
  setBasket: React.Dispatch<React.SetStateAction<{product: Product, quantity: number, basketPrice: number, gp: number}[]>>,
  setActiveBasketItem: (productCode: string) => void,
  activeBasketItem: string | null
) => {
  const updatedBasket = [...basket];
  const indexOfActiveItem = basket.findIndex((item) => item.product["Product code"] === activeBasketItem);
  const indexOfItem = basket.findIndex((item) => item.product["Product code"] === id);

  console.log(indexOfItem, indexOfActiveItem, basket.length - 1);

  if (indexOfItem === indexOfActiveItem && basket.length > 1) {
    indexOfActiveItem === basket.length - 1
      ? setActiveBasketItem(basket[indexOfItem - 1].product["Product code"])
      : setActiveBasketItem(basket[indexOfItem + 1].product["Product code"])
  }
  console.log(indexOfItem, basket[indexOfItem + 1]);
  updatedBasket.splice(basket.findIndex((item) => item.product["Product code"] === id), 1);
  setBasket(updatedBasket);
}