type MetricChangeProps = {
  metric: string;
  metricChange: number;
  giveTextColor?: boolean;
}

const MetricChangeHandler = ({ metric, metricChange, giveTextColor }: MetricChangeProps) => {

  let leadingString, initialUnit, followingUnit;

  if (metricChange > 0) {
    leadingString = "+";
  }

  followingUnit = "%";

  return (
    <span className="text-widget-blue font-bold">
      {leadingString}
      {initialUnit}
      {metricChange.toFixed(0)}
      {followingUnit}
    </span>
  )

}

export default MetricChangeHandler;