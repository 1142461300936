import MetricChangeHandler from "./metricChangeHandler";

type SimpleMetricCardProps = {
  identifier: string;
  count: number;
  change: number;
  period: string;
  metric: string;
  icon: string;
  chartColor: "pink" | "green" | "black" | "blue" | "yellow" | "red";
}

const SimpleMetricCard = ({ identifier, count, change, period, metric, icon, chartColor }: SimpleMetricCardProps) => {

  const includeCurrencySymbol = metric === "Sales" || metric === "Profit";

  return (
    <div className="dashboard-card p-4 flex flex-col justify-between">
      <div className="flex items-start gap-4 mb-2">
        <div className="bg-widget-blue text-widget-blue rounded-xl p-2 md:p-3 md:px-4 text-xl md:text-2xl my-auto">
          <i className={`fas fa-${icon}`} data-testid='icon-testid'></i>
        </div>

        <div className="">
          <h3 className="font-bold text-xl md:text-4xl">
            {!count
              ? <i className="fa-solid fa-spinner fa-spin text-xl"></i>
              : <>
                  {includeCurrencySymbol && "£"}
                  {count > 10000 ? `${(count / 1000).toFixed(0)}K` : count.toLocaleString()}
                </>
            }
          </h3>
         <p className="text-muted text-xs mt-1">{identifier}&nbsp;{metric}&nbsp;this&nbsp;{period}.</p>
       </div>

        <i className="cursor-pointer fa-regular fa-ellipsis-vertical text-muted ml-auto hover:text-black" ></i>
      </div>

      <p className="text-muted text-xs mt-6 md:mt-8">
        {!count
          ? <i className="fa-solid fa-spinner fa-spin"></i>
          : <MetricChangeHandler metric={metric} metricChange={change} giveTextColor={true} />
        }
        &nbsp;than last {period}.
      </p>
    </div>
  )

}

export default SimpleMetricCard;